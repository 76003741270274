/*
 * @Author: kok-s0s
 * @Date: 2021-08-17 17:39:08
 * @LastEditors: kok-s0s
 * @LastEditTime: 2021-08-17 17:45:31
 * @Description: file content
 */
import React from "react";
import ReactDOM from "react-dom";
import Timer from "./App";

ReactDOM.render(
	<React.StrictMode>
		<Timer />
	</React.StrictMode>,
	document.getElementById("app")
);
